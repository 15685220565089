import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { AlertsStatuses } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';

interface UpdateStatusModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId: string }) => void;
  alert: any;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const alertsStatusOptions = generateSelectOptions(AlertsStatuses);

const UpdateStatusModal = ({ isModalVisible, onCloseModal, alert, refreshList }: UpdateStatusModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<any>();

  const { dataSource } = useDataSource();

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        status: alert.status,
      });
    }
  }, [isModalVisible]);

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, []);

  const handleSubmit = async (item: any) => {
    try {
      setConfirmLoading(true);
      await dataSource?.services?.alertService?.updateAlertStatus(alert.uuid, item.status);
      refreshList({ editedRowId: alert.uuid });
      handleClose();
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <CModal
      title={`Update alert status for: ${alert?.uuid}`}
      open={isModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      confirmLoading={confirmLoading}
      maskClosable={false}
      style={{ textAlign: 'start' }}
    >
      <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} className="max-w-2xl">
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select options={alertsStatusOptions} />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default UpdateStatusModal;
