import React, { useCallback, useState } from 'react';
import { Form, Select } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { AlertsStatuses } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';

interface BulkUpdateStatusModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId?: string }) => void;
  alertUUIDs: string[];
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const alertsStatusOptions = generateSelectOptions(AlertsStatuses);

const BulkUpdateStatusModal = ({
  isModalVisible,
  onCloseModal,
  alertUUIDs,
  refreshList,
}: BulkUpdateStatusModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [form] = Form.useForm<any>();

  const { dataSource } = useDataSource();

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, []);

  const updateAStatus = useCallback(async (uuid: string, status: string) => {
    try {
      return await dataSource?.services?.alertService?.updateAlertStatus(uuid, status);
    } catch {}
  }, []);

  const handleSubmit = useCallback(
    async (item: any) => {
      try {
        setConfirmLoading(true);
        await Promise.all(
          alertUUIDs.map(async (uuid: string) => {
            return await updateAStatus(uuid, item.status);
          })
        );
        refreshList({});
        handleClose();
      } finally {
        setConfirmLoading(false);
      }
    },
    [alertUUIDs]
  );

  return (
    <CModal
      title="Bulk update alert status"
      open={isModalVisible}
      confirmLoading={confirmLoading}
      onOk={form.submit}
      onCancel={handleClose}
      style={{ textAlign: 'start' }}
      maskClosable={false}
    >
      <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select options={alertsStatusOptions} />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default BulkUpdateStatusModal;
