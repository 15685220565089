import React from 'react';
import { Tooltip } from 'antd';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

type OverflowTextCellProps = {
  text: string;
  hoverText?: string;
  onClick?: VoidFunction;
};

const getStyles = () => {
  return {
    container: css({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };
};

export const OverflowTextCell = ({ text, hoverText, onClick }: OverflowTextCellProps) => {
  const styles = useStyles2(getStyles);

  return (
    <Tooltip placement="topLeft" title={hoverText ?? text}>
      <div className={styles.container} onClick={onClick}>
        {text}
      </div>
    </Tooltip>
  );
};
