import React, { useState } from 'react';
import { Button, Descriptions, Popover, Tag, Timeline, Tooltip } from 'antd';
import { css } from '@emotion/css';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useStyles2 } from '@grafana/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface AlertTransactionsTableProps {
  transactions: any[];
}

const getStyles = () => {
  return {
    container: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
    itemContainer: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '10px',
    }),
    tag: css({
      width: '150px',
      textAlign: 'center',
    }),
    timeline: css({
      width: '100%',
      maxHeight: '400px',
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingTop: '20px',
      paddingLeft: '10px',
    }),
  };
};

const TransactionDetails = (props: any) => {
  const { transaction } = props;

  const items = Object.keys(transaction).map((aProp: any) => ({
    key: aProp,
    label: aProp,
    children: transaction[aProp],
  }));

  return <Descriptions style={{ width: '400px' }} items={items} column={1} />;
};

const AlertTransactionsTable = ({ transactions }: AlertTransactionsTableProps) => {
  const [isDescending, setIsDescending] = useState<boolean>(false);

  const styles = useStyles2(getStyles);

  const items = transactions.map((aTransaction: any) => {
    let dotColor = 'green';
    let dot = <CheckCircleOutlined style={{ color: 'green' }} />;
    switch (aTransaction.status) {
      case 'active':
        dotColor = 'red';
        dot = <QuestionCircleOutlined style={{ color: 'red' }} />;
        break;
      case 'acknowledged':
        dotColor = 'orange';
        dot = <ExclamationCircleOutlined style={{ color: 'orange' }} />;
        break;
      default:
        break;
    }

    let severityColor = '#021691';
    switch (aTransaction.severity) {
      case 'crucial':
        severityColor = '#8B0000';
        break;
      case 'minor':
        severityColor = '#ff8c00';
        break;
      case 'warning':
        severityColor = '#8B8000';
        break;
      default:
        break;
    }

    return {
      color: dotColor,
      dot: dot,
      children: (
        <div className={styles.itemContainer}>
          <Tag className={styles.tag}>{`${
            dayjs(aTransaction.created_at).local().format('YYYY-MM-DD HH:mm:ss') || 'N/A'
          } `}</Tag>

          <span>{aTransaction.title}</span>

          <Tag color={dotColor}>{`status: ${aTransaction.status}`}</Tag>

          <Tag color={severityColor}>{`severity: ${aTransaction.severity}`}</Tag>

          <Popover
            content={<TransactionDetails transaction={aTransaction} />}
            title="Transaction Details"
            trigger={'click'}
          >
            <Tooltip title={'More Info'}>
              <MoreOutlined className="cursor-pointer" />
            </Tooltip>
          </Popover>
        </div>
      ),
    };
  });

  return (
    <div className={styles.container}>
      <Button style={{ marginBottom: '15px' }} onClick={() => setIsDescending(!isDescending)}>
        Sort by Time
      </Button>

      <Timeline className={styles.timeline} mode={'left'} items={(isDescending ? items.reverse() : items) || []} />
    </div>
  );
};

export default AlertTransactionsTable;
